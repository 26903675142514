@import "../helpers/variables.scss";

.v-data-table td {
  height: 58px;
}

.box-table .v-toolbar__content {
  padding: 4px 0;
}

.theme--light.v-data-table {
  background-color: $colorWrapper !important;
}

.v-application .box-table .white {
  background-color: $colorWrapper !important;
  border-color: $colorWrapper !important;
}

.box-table {
  .wrapper-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    .qtd-paginacao {
      width: 100%;
      min-width: fit-content;
      font-size: 12px;
    }

    .box-table-paginacao {
      margin-top: 0;
      width: 100%;
      justify-content: flex-end;
      display: flex;

      .v-pagination {
        width: auto;
      }

      li {
        &:focus {
          outline: none !important;
        }

        button {
          outline: none !important;
        }
      }
    }
  }

  .box-table-paginacao {
    margin-top: 20px;
  }

  .v-data-table__wrapper {
    background: #fff;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 1px 0px 5px rgba(0, 0, 0, 0.2);
  }

  .icon-boleto {
    cursor: pointer;
  }

  .v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th,
  .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
    user-select: all;
    padding: 5px;
  }

  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
  .v-data-table > .v-data-table__wrapper > table > thead > tr > td,
  .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
    height: 23px;
    font-size: 12px;
    padding: 5px;
  }

  .theme--light.v-data-table tbody tr:nth-of-type(even) {
    background-color: rgba(0, 0, 0, 0.03);
  }
  .theme--dark.v-data-table tbody tr:nth-of-type(even) {
    background-color: rgba(0, 0, 0, 0.5);
  }

  .v-btn {
    letter-spacing: normal;
    text-transform: none;
    height: 18px !important;
    font-size: 12px !important;
  }

  .v-chip.v-size--default {
    height: 23px;
    font-size: 12px;
    border-radius: 4px;
  }
}
